<template>
    <div>
         <div class="modulo_header" style="background-color: white">
            
            <div class="container" style="background-color: white">
                <div class="row">
                        <div class="title_head_interna">
                            <div class="btn_voltar">
                                <a href="home"><span class="jam jam-arrow-left"></span></a>
                            </div>
                        </div>
                </div>
                <div class="" style="background-color: white">
                    <div class="title_medclub">
                        <h1>MEDCLUB EMPRESA</h1>
                    </div>
                    <div class="title_header">
                        <h1>O QUE É O MEDCLUB EMPRESA?</h1>
                        <p>O <b>Medclub</b> não é um plano de saúde. No plano de saúde você paga uma mensalidade, que sofre altos reajustes todos os anos. Todo mês tem que pagar, mesmo que você passe anos sem usar nada. E o dinheiro nunca volta para seu bolso. 
                        No plano de saúde o contrato diz que você terá consultas, exames e internações em hospitais, sempre que precisar. Mas não é isso que acontece. E não acontece porque o contrato entre as 2 partes - você e o plano de saúde - é cruel para ambos os atores, que logo passam a competir um contra o outro. 
                        </p>
                        <br/>
                        <p>De um lado está o plano, que já recebeu pagamento antecipado e faz tudo para ter mais lucros, dificultando acesso aos serviços solicitados. E quando tem que entregar serviços, o plano escolhe aqueles mais baratos e portanto, de menor qualidade. 
                        Do outro lado está você, o cliente, que adota um comportamento imediatista, guiado pelo impulso de pensar: -“Ora, estou pagando, logo tenho que usar muito”. E passa a se deixar levar pela propaganda, consumindo consultas, exames e cirurgias, sem parar. 
                        </p>
                        <br/>
                        <p>
                        E assim, em virtude do completo desacordo de propósitos do atual modelo, clientes e médicos sonham com um nova forma de relacionamento que elimine desperdícios e conflitos. 
                        A solução para tal cenário se chama Medclub.
                        </p>
                        
                        <div class='card_perfil'>
                            <img src="@/assets/img/logo-nova.png" class="img-card">
                            <span style='font-size: 20px; font-weight: bold; text-align: end; width: 200px; margin-top: -10px; color: #322c66'>empresa</span>
                        </div>
                        <p>
                        Com o <b>Medclub Empresas</b> o funcionário deposita, no início da cada mês, numa conta de aplicação financeira junto à Caixa Economica, um valor definido em reunião coletiva. O valor da soma de todos os depósitos feitos pelos funcionários será imediatamente aplicado em investimento financeiro, com rendimento diário. 
                        </p>
                        <br/>
                        <p>
                        Cada funcionário terá acesso direto ao extrato do seu investimento pessoal. No final de cada mês, o Medclub enviará à empresa uma fatura com o nome de cada funcionário que usou o Medclub nos últimos 30 dias, listando o que ele usou e quanto custou. Esse valor é descontado da aplicação, para pagar os médicos, clinicas e hospitais. O restante do dinheiro continuará na conta bancaria, como investimento. Quando o funcionário pedir demissão, for demitido ou falecer, o dinheiro dos depósitos, somado aos ganhos da aplicação, será devolvido a ele, ou aos seus herdeiros. É como um segundo FGTS. 
                        Como o Medclub já possui mais de 200 mil clientes, consegue grandes descontos em preços de consultas, exames e cirurgias. 
                        </p>
                        <br/>
                        <p>Além disso, no Medclub, somente médicos com título de especialista e comprovada competência podem atender nossos clientes. 
                        E precisamos, também, lhe apresentar o maravilhoso Clube de Vantagens Medclub, que permite grandes descontos nas melhores farmácias, academias, fornecedores de aparelhos médicos, salões de beleza, etc. 
                        </p>
                    </div>
                    <div class="title_header">
                        <h1>COMO USAR O MEDCLUB EMPRESA?</h1>
                        <p>Ao contrário dos planos de saude, onde a burocracia é enorme, com longas carências e muitas proibições, no Medclub tudo é muito ágil. 
                        </p>
                        <br/>
                        <p>Precisa de uma consulta, ou exame? Bastará que você ligue para nosso CallCenter <a href="https://api.whatsapp.com/send?phone=558631311881"><u>(86) 3131-1881</u></a> esclarecendo duvidas e agendando suas consultas e exames. Se você preferir seu celular, tudo poderá ser feito baixando e usando o aplicativo Medclub. No aplicativo Medclub você encontrará toda a ajuda que precisa, podendo também falar com médicos, mandando suas perguntas pelo “Pergunte ao Doutor”, que já conta com mais de 35 milhões de acessos. 
                        </p>
                        <br/>
                        <p>
                        Ah, mais um detalhe importante: nos planos de saude você nem sabe quem são os donos, você não os conhece e eles não querem falar com você. No Medclub, você conhece os donos. E pode falar com eles, na hora que desejar. 
                        Experimente e nos ajude a melhorar. 
                        <br/>
                        <br/>
                        <b>Cerqueira:</b> (86) 99981-1696<br/>
                        <b>Ilana:</b> (86) 98148-2005<br/>
                        <b>Aldo Giordano:</b> (86) 99828-1488<br/> </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style>
    .title_header{
        display: flex;
        flex-direction: column ;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 0;
        background-color: #fff;
        margin-bottom: 65px;
    
    }
    .title_header h1 {
        font-size: 1rem;
        font-weight: 800;
        margin-bottom: 20px;
        text-align: left;
    }
    .title_medclub{
        display: flex;
        flex-direction: column ;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 0;
        background-color: #fff;
        margin-bottom: 65px;
    
    }
    .title_medclub h1 {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 20px;
        vertical-align: middle;
    }
    .container_body {
        margin-left: 200px;
    }
    .container_body {
        font-size: 1.4rem;
        font-weight: 800;
        vertical-align: middle;
    }
    .img-card {
        width: 200px;
        margin: 10px auto;
    }
    .card_perfil {
        background-color: transparent;
        width: 400px;
        height: 189px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 9999;
        padding: 20px;
    }
    @media only screen and (min-width: 769px) {
        .title_header {
            padding-right: 200px;
            padding-left: 200px;
        } 
       
    }

</style>

<script>
export default {
    data() {
        return {
            waitingLocal: true,
            tokenAPI: '',
            localAtendimento: {}
        }
    },
    created() {
        
    },
   
}
</script>
